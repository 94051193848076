var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('PageTitle',{attrs:{"items":{
      title: '教育機関',
      buttons: [
        {
          title: '詳細条件検索',
          action: function () {
            _vm.toggleSearch = true
          },
          class: ['bg-white'],
          others: {
            outlined: true
          }
        },
        {
          title: '新規作成',
          action: function () {
            _vm.lauunchNew = true
          },
          icon: 'mdi-plus-circle'
        }
      ]
    }}}),_c('v-fade-transition',[(_vm.toggleSearch)?_c('SearchBox',_vm._b({staticClass:"mb-5",attrs:{"searchPlacholder":"学校名"},on:{"toggleSearch":function($event){_vm.toggleSearch = false},"search-table":_vm.searchTable}},'SearchBox',{ toggleSearch: _vm.toggleSearch },false)):_vm._e()],1),_c('DataTable',{staticClass:"school-table",attrs:{"headers":_vm.headers,"items":_vm.getFacilities,"total-records":_vm.totalRecords ? _vm.totalRecords : 0,"number-of-pages":_vm.totalPages ? _vm.totalPages : 0},on:{"row-clicked":function($event){_vm.lauunchEdit = true
      _vm.editItem = $event},"update:options":_vm.updateTable},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.type ? item.type.name : '')+" ")]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","color":"transparent"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteInitiate(item.id)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$delete")])],1)]}}])}),_c('SchoolDialog',{attrs:{"launch":_vm.lauunchNew,"edit":false},on:{"update:launch":function($event){_vm.lauunchNew=$event},"refresh":_vm.getDataFromApi}}),_c('SchoolDialog',{attrs:{"launch":_vm.lauunchEdit,"item":_vm.editItem},on:{"update:launch":function($event){_vm.lauunchEdit=$event}}}),_c('SimpleModel',{attrs:{"text":"この教育機関を削除してよろしいですか？","dialog":_vm.dialog.delete,"submitButtonText":'削除する'},on:{"submitSuccess":function($event){return _vm.deleteInstituition()},"closeModel":function($event){_vm.dialog.delete = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }